<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-menu-schedule-history-Detail")
            }}</h1>
        <div class="content-box">
            <h2>상세정보</h2>
            <div class="sd-detail">
                <ul class="detail-info">
                    <li>
                        <span>{{ $t("station-data-name") }}</span>
                        <h4>{{ stationName }}</h4>
                    </li>
                    <li>
                        <span>{{ $t("station-data-schedule-title") }}</span>
                        <h4>{{scheduleTitle}}</h4>
                    </li>
                    <li>
                        <span>{{ $t("station-data-mission-type") }}</span>
                        <h4>{{handleMissionType(missionType)}}</h4>
                    </li>
                    <li>
                        <span>{{ $t("station-data-flight-type") }}</span>
                        <h4>{{flyTypeObject[flyType]}}</h4>
                    </li>
                    <li>
                        <span>{{ $t("device-data-request") }}</span>
                        <h4>{{handleRequestStatus(requestStatus)}}<span v-if="requestStatus === 'Failed' && requestMessage !== null" class="failure-message">{{ requestMessage }}</span></h4>
                    </li>
                    <li>
                        <span>{{ $t("device-data-work") }}</span>
                        <h4>{{ handleRequestStatus(workJobStatus) }}<span v-if="workJobStatus === 'Failed' && workJobFailMessage !== null" class="failure-message">{{ workStateMessage }}</span></h4>
                    </li>
                    <li>
                        <span>{{ $t("device-data-manager-dept-name") }}</span>
                        <h4>{{departmentName}}</h4>
                    </li>
                    <li>
                        <span>{{ $t("device-data-manager-operator") }}</span>
                        <h4>{{writer}}</h4>
                    </li>
                    <li>
                        <span>{{ $t("station-data-flight-time") }}</span>
                        <h4>{{  flightDate +" "+ flightTime  }}</h4>
                    </li>
                </ul>
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"/>
                    <MapButtonComponent  ref="buttonMap" class="schedule-history-map-button"/>
                    <span v-if="locationSize == 0"
                        style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                        {{ $t("popup-location-not-found") }}
                    </span>
                </div>
            </div>
            <div class="sd-detail-btn">
                <button @click="$router.go(-1)">목록으로 가기</button>
                <button @click="RegisterAgain">스케줄 재등록</button>
            </div>
        </div>
    </div>
</template>
<style>

.title {
    display: none;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.content-box>h2 {
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
}

.sd-detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.detail-info {
    width: calc(50% - 20px);
}

.detail-info>li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.detail-info>li>span {
    padding: 3px 0;
    background: #eee;
    width: 110px;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 10px;
    color: #969696;
}

.detail-info>li>h4 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 20px;
}

.sd-detail>.map {
    width: 50%;
    height: 450px;
}

.sd-detail-btn {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    justify-content: space-between;
}

.sd-detail-btn > button {
    background: #0080FF;
    border-radius: 5px;
    border: 0;
    color: #ffffff;
    padding: 0 20px;
    margin: 0 5px;
    height: 40px;
    font-weight: 400;
}
.sd-detail-btn > button:nth-child(2){
    background: #ffffff;
    border: 1px solid #0080FF;
    color: #0080FF;
}

.sd-detail-btn>button:hover {
    color: #fff;
    background: #0e73c4;
}
.sd-detail-btn > button:nth-child(2):hover{
    background: #ffffff;
    border: 1px solid #0080FF;
    color: #0080FF;
}
.failure-message{color: #ff3838;font-size: 1.4rem;font-weight: 400;margin-left: 20px;}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import MapMappingData from "@/components/map/data/MapMappingData";
import MapUtils from "@/components/common/MapUtils"

// import { mapState } from "vuex";
import { ko } from "vuejs-datepicker/dist/locale";
import {fetchScheduleHistoryDetail} from '@/api/station/stationHistory'
import { fetchMissionLocationList } from "@/api/missionLocation";
import { getMissionCameraList } from "@/api/mission";
import { fetchMissionInfo } from "@/api/mission";
import { fetchStationDeviceInfo } from "@/api/station/stationDevice";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
export default {
    components: { Map, MapButtonComponent },
    name: "ScheduleHistoryDetail",
    props: {
        eventId: {
            type: String,
            default: "",
        },
        
    },
    data() {
        return {
            moment: moment,
            loadMap: 'kakao',
            ko: ko,
            locationSize: null,
            
            scheduleItemLogId: null,

            // 매핑 그리는데 필수요소들.
            mapDataType: {},
            cameraTypeList: [],
            mappingData: new MapMappingData(),
            MapUtils: new MapUtils(),
            missionLocationList: [],
            polygonType : "polygon",
            mappingType : "Orthophoto",

            // 화면에서 사영하는 데이터
            stationName: null,
            scheduleTitle: null,
            missionType: null,
            flyType: null,
            departmentName: null,
            writer: null,
            flightTime: null,
            missionId : null,
            stationId: null,
            missionInfo: null,
            requestStatus: null,
            requestMessage: null,
            workJobStatus: null,
            workStateMessage: null,
            flyTypeObject: {
                "Immediately": "즉시비행",
                'Reserve' : '예약비행',
                'Period' : '반복비행'
            }
        }
    },
    mounted() {
        this.initMapData(this.$store.state.store.currentLang);
        this.$refs.map.isShowLine = true
        this.initButtonMap()
    },
    created() {
        this.scheduleItemLogId = this.$route.params.scheduleItemLogId
        this.getScheduleInfo()
        this.cameraInit()
        
    },
    methods: {
        initButtonMap() {
            this.$refs.buttonMap.showMapTypeChange()
            this.$refs.buttonMap.showMapCenter()
        },
        /**
         * 스테이션 스케줄 GET
         * 지금은 대충 화면보려고 scheduleDetail가져오게 해둠
         */
        getScheduleInfo(){
            fetchScheduleHistoryDetail(this.scheduleItemLogId).then((res) => {
                console.log("detail",res.data.data)

              const data = res.data.data
              this.stationName = data.deviceName
              this.scheduleTitle = data.scheduleName
              this.missionType = data.missionType
              this.flyType = data.scheduleType
              this.departmentName = data.managerDepartmentName
              this.writer = data.managerUserName
              this.flightDate = data.runLocalDate
              this.flightTime = data.runLocalTime
              this.requestStatus = data.requestStatus
              this.requestMessage = data.requestFailMessage
              this.workJobStatus = data.workJobStatus
              this.workStateMessage = data.workJobFailMessage
              this.missionId = data.missionId
              this.stationId = data.stationDeviceId

              this.setMapDateType()
              this.getMissionInfo()
              this.getStationInfo(this.stationId)
                // this.drawStationMarker()
            })
        },

        getStationInfo(stationId) {
            fetchStationDeviceInfo(stationId).then((res) => {
                const stationInfo = res.data.data.managerData
                this.drawStationMarker(stationInfo)
            })
        },

        /**
         * 요청응답에 따른 리턴
         * @param requestStatus 요청상태
         */
        handleRequestStatus(requestStatus) {
            if(requestStatus === undefined ||requestStatus === 'Failed' || requestStatus === null) {
                return '요청실패'
            }
            return '요청성공'
        },
        handleMissionType(responseType) {
            if (responseType === 'Mapping') {
                return '매핑'
            } else if (responseType === 'WayPoint') {
                return '웨이포인트';
            } 
            return responseType;  
        },
        /**
         * 카메라정보 GET
         */
        cameraInit() {
            getMissionCameraList().then(res => {
                const data = JSON.parse(res.data.data)
                this.cameraTypeList = data.DJI
            })
        },
        
        getMissionInfo(){
            fetchMissionInfo(this.missionId).then((res)=> {
                this.missionInfo = res.data.data
                this.getLocationList(this.missionId)
            })
        },

        /**
         * 맵 init
         */
        initMapData(value) {
            if (value == 'en') {
                this.loadMap = 'google'
            } else {
                this.loadMap = 'kakao'
            }
        },

        drawStationMarker(stationInfo) {
            const mapDataType = MapDataType.ID_SCHEDULE_STATION
            const mapMarkerData = new MapMarkerData();
            mapMarkerData.id = stationInfo.tag;
            mapMarkerData.name = stationInfo.stationDeviceId;
            mapMarkerData.lng = stationInfo.lng || 127.1207235830093;
            mapMarkerData.lat = stationInfo.lat || 37.54941346481594;
            mapMarkerData.isMain = true
            
            if (this.$refs.map.onIsMarker(mapDataType, 0)) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
            // this.$refs.map.onLngLatToMoveCenter(this.stations[this.activeIndex].managerData.lng, this.stations[this.activeIndex].device.managerData.lat)
        },

        setMapDateType(){
            if(this.missionType === "WayPoint"){
                this.mapDataType.marker = MapDataType.ID_WAYPOINT 
                this.mapDataType.line =MapDataType.ID_WAYPOINT 
            }else{
                this.mapDataType.marker = MapDataType.ID_MAPPING_MARKER 
                this.mapDataType.line =MapDataType.ID_MAPPING_POLYGON_LINE
                this.mapDataType.inLine = MapDataType.ID_MAPPING_POLYLINE
                this.mapDataType.polygone = MapDataType.ID_MAPPING_HIDDEN_POLYGON
            }
        },
        

        getLocationList(missionId) {
            const params = {
                eventId: this.eventId,
                missionId: missionId
            };
            fetchMissionLocationList(params)
                .then((res) => {
                    if (res.data.result == 0) {
                        console.log("locationMission",res.data.data)
                        this.missionLocationList = res.data.data.content;
                        const marker = this.missionLocationList[0]
                        this.$refs.map.onLngLatToMoveCenter(marker.lng, marker.lat);
                        this.missionLocationList.forEach(marker =>{
                            this.createMarker(marker, this.mapDataType.marker)
                        })
                        if(this.mapDataType.marker === MapDataType.ID_MAPPING_MARKER){
                            this.settingMappingData()
                            this.createLastLine(this.missionLocationList.length)
                            this.createMappingLine()
                        }
                    }
                })
        },

        // 좌표개수대로 반복해서 마커 찍음.
        createMarker(markerData,markerType) {
            const index = markerData.index + 1
            const mapMarkerData = new MapMarkerData();
            mapMarkerData.id = index;
            mapMarkerData.lng = markerData.lng;
            mapMarkerData.lat = markerData.lat;
            mapMarkerData.rotate = markerData.azimuth;
            mapMarkerData.name = index;

            if (this.$refs.map.onIsMarker(markerType, index)) {
                this.$refs.map.onModifyMarkerAt(markerType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(markerType, mapMarkerData);
            }

            if (index > 1) {
                let slice = this.missionLocationList.slice(index - 2, index);
                this.createMapLine(index - 1, slice);
            }

            // if(markerType === MapDataType.ID_MAPPING_MARKER && index > 2) {
            //     this.createLastLine(index)
            // }
            

        },

        /**
         * @params 정렬된 좌표목록들 가져와서 라인 그리게 함.
         */
        createMapLine(id, locationGroup) {
            let mapLineData = new MapLineData();
            mapLineData.id = id;
            mapLineData.locationGroup = locationGroup;
            mapLineData.isMain = true;
            mapLineData.isShow = true;
            
            if (this.$refs.map.onIsLine(this.mapDataType.line, id)) {
                this.$refs.map.onModifyLineAddAt(this.mapDataType.line, mapLineData);
            } else {
                this.$refs.map.onCreateLine(this.mapDataType.line, mapLineData);
            }
        },

        // 매핑일때 마지막 라인
        createLastLine(index) {
            if (index > 2) {
                let slice = [
                    this.missionLocationList[0], // 첫 번째 요소
                    this.missionLocationList[index - 1] // 마지막 요소
                ];
                this.createMapLine(index, slice);
                
            }
        },

        createMappingLine(dataType) {
            this.sendMappingData(dataType);
            this.createPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
            this.$refs.map.onCreateMappingLine(this.mappingType, this.polygonType)
        },

        /**폴리곤 생성.*/
        createPolygon(mapDataType) {
            let mapPolygonDate = new MapPolygonData();
            if (mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON) {
                let centerInfo = this.createCenterInfo()
                mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
                mapPolygonDate.setLatLng(centerInfo)
                mapPolygonDate.posList = this.createSqurePolygon(centerInfo, centerInfo.distance, Number(this.mappingData.angle))
                mapPolygonDate.line = mapPolygonDate.posList
            }
            this.$refs.map.onCreateMappingPolygon(mapPolygonDate, mapDataType);
        },

        /**다각형 도형의 가운데 정보를 생성한다.*/
        createCenterInfo() {
            let maxDistance = 0
            let center = this.MapUtils.calculateCentroid(this.missionLocationList) //다각형 중점 구하기

            this.missionLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
                let distance = this.MapUtils.calculateDistance(item, center)
                if (maxDistance < distance) maxDistance = distance
            })
            var data = { lat: center.lat, lng: center.lng, distance: maxDistance }
           
            return data
        },

        /**매핑 영역 밑에 깔리는 폴릭곤 */
        createSqurePolygon(point, distance, angle) {
            let polygonPosList = []

            let rightPoint = this.MapUtils.calculateNewPosition(point, 90 + angle, distance);
            polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint, 0 + angle, distance, polygonPosList.length));
            polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint, 180 + angle, distance, polygonPosList.length));


            let leftPoint = this.MapUtils.calculateNewPosition(point, 270 + angle, distance);
            polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint, 180 + angle, distance, polygonPosList.length));
            polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint, 0 + angle, distance, polygonPosList.length));

            return polygonPosList
        },

        settingMappingData() {
            if (this.missionInfo != null) {
                const findIndex = this.cameraTypeList.findIndex((item) => { 
                    return item.id === this.missionInfo.info.cameraType
                })
                if (findIndex == -1) {
                    console.log("settingMappingData index : -1");
                    return
                }
                let camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
                this.missionInfo.info.cameraType = camera
                
                // this.cameraInfo.cameraType = camera
                this.mappingData.setMissionToMapping(this.missionInfo)
                this.sendMappingData();
            } else {
                console.log("missionInfo is null!")
            }
        },

        sendMappingData(dataType) {
            this.$refs.map.sendMappingData(this.mappingData, dataType)
        },
        RegisterAgain(){
            const userConfirmed = window.confirm('스케줄을 다시 등록하시겠습니까? 등록 시 즉시 비행으로 바로 등록됩니다.')
            if(userConfirmed){
                this.$router.push({ name: "SchedulePlan" });
            }
            else{
                console.log('사용자가 취소했습니다.')
            }
        },
        
    },

};
</script>
